<template>
  <v-form
    ref="form"
    v-model="formIsValid"
  >
    <!-- COMMENT FIELD -->
    <mavon-editor
      v-if="!selectedCategory || selectedCategory.type === 'text'"
      ref="editor-0"
      v-model="commentValue"
      class="mb-2"
      :placeholder="selectedCategory ? selectedCategory.description : 'Your comment'"
      :toolbars="require('@/mavonToolbarsMinimal').default"
      :box-shadow="false"
      language="en"
      @imgAdd="(pos, $file) => $imgAdd(pos, $file, 'editor-0')"
    />
    <v-text-field
      v-else-if="selectedCategory.type === 'number'"
      v-model.number="commentValue"
      :label="selectedCategory.description"
      :rules="[
        (v) => !!v || 'This field is required',
        (v) => _.isNumber(v) && parseInt(v) > 0 || 'Must be a positive numerical value',
      ]"
      type="number"
      clearable
      outlined
    />
    <!-- ADDITIONAL FIELDS -->
    <v-select
      v-model="selectedCategory"
      :items="currentEvent.commentCategories"
      item-text="name"
      item-value="id"
      class="mb-2"
      label="Comment Category"
      :menu-props="{ top: false, offsetY: true }"
      return-object
      clearable
      hide-details
      outlined
      dense
    >
      <template v-slot:item="{ item }">
        <div>
          <div>
            {{ item.display_name }}
          </div>
          <div class="text-caption text--disabled">
            {{ item.description }}
          </div>
        </div>
      </template>
      <template v-slot:selection="{ item }">
        <div>
          <div>
            {{ item.display_name }}
          </div>
          <div class="text-caption text--disabled">
            {{ item.description }}
          </div>
        </div>
      </template>
    </v-select>
    <v-autocomplete
      v-model="selectedTags"
      :search-input.sync="selectedTagsInput"
      class="mb-2"
      :items="currentEvent.tags"
      item-text="display_name"
      item-value="id"
      label="Related Tags"
      :menu-props="{ top: false, offsetY: true }"
      :disabled="!currentEvent.tags.length"
      auto-select-first
      return-object
      clearable
      multiple
      hide-details
      outlined
      @change="selectedTagsInput = null"
    >
      <template v-slot:selection="{ item }">
        <v-chip
          label
          close
          @click:close="selectedTags = selectedTags.filter(tag => tag.id !== item.id)"
        >
          {{ item.display_name }}
        </v-chip>
      </template>
    </v-autocomplete>

    <v-btn
      class="mb-2"
      :loading="createLoading"
      :disabled="createLoading || !formIsValid || !commentValue"
      color="primary"
      depressed
      @click="createMutation({ project_id: project.id, category_id: selectedCategory ? selectedCategory.id : null, value: `${commentValue}`, tags: selectedTags.map(tag => tag.id) })"
    >
      Send
    </v-btn>

    <v-card
      class="mt-0"
      min-height="128"
      outlined
    >
      <v-overlay
        v-if="$apollo.queries.comments.loading"
        color="white"
        opacity="0"
        absolute
      >
        <v-progress-circular
          :size="64"
          color="primary"
          indeterminate
        />
      </v-overlay>
      <div
        v-else
        class="pa-2"
      >
        <v-row
          dense
        >
          <v-col
            v-if="comments.length > 1 && usedCommentCategories.length > 1"
          >
            <v-select
              v-model="filterCommentsByCommentCategory"
              :items="[
                'All',
                ..._(usedCommentCategories).map('display_name').remove(null).value(),
                'Without Category',
              ]"
              class="mb-2"
              label="Filter by category"
              hide-details
              outlined
              dense
            />
          </v-col>
          <v-col
            v-if="comments.length > 1 && usedTags.length > 1"
          >
            <v-select
              v-model="filterCommentsByTag"
              :items="[
                { id: null, display_name: 'All' },
                ...usedTags,
              ]"
              item-text="display_name"
              item-value="id"
              class="mb-2"
              label="Filter by tag"
              hide-details
              outlined
              dense
            />
          </v-col>
        </v-row>
        <transition-group
          v-if="filteredComments.length"
          name="flip-list"
        >
          <project-comment
            v-for="(comment, index) in filteredComments"
            :key="comment.id"
            :class="{ 'mb-2': index !== filteredComments.length - 1 }"
            :comment="comment"
            :event="currentEvent"
            :project="project"
          />
        </transition-group>
        <div
          v-else
        >
          <div class="grey--text">
            No Comments...
          </div>
        </div>
      </div>
    </v-card>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'

import ProjectComment from './ProjectComment'

export default {
  name: 'ProjectComments',
  components: { ProjectComment },
  props: {
    project: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    filterCommentsByCommentCategory: 'All',
    filterCommentsByTag: null,
    selectedCategory: null,
    commentValue: '',
    selectedTags: [],
    selectedTagsInput: null,
    comments: [],
    createLoading: false,
    formIsValid: false
  }),
  computed: {
    ...mapState('user', ['claims']),
    currentEvent () {
      if (!this.$route.params.eventSlug) return null
      const client = this.$apollo.getClient()
      const { event } = client.readQuery({
        query: require('@/gql/getEvent').default,
        variables: {
          slug: this.$route.params.eventSlug
        }
      })
      return event
    },
    filteredComments () {
      if (!this.filterCommentsByCommentCategory && !this.filterCommentsByTag) return this._(this.comments).orderBy(['created'], ['desc']).value()
      return this._(this.comments)
        .filter(comment => {
          if (this.filterCommentsByCommentCategory === 'All') return true
          else if (this.filterCommentsByCommentCategory === 'Without Category') return !comment.category
          else return comment.category && comment.category.display_name === this.filterCommentsByCommentCategory
        })
        .filter(comment => this.filterCommentsByTag ? comment.tags.map(tag => tag.tag.id).includes(this.filterCommentsByTag) : true)
        .orderBy(['created'], ['desc'])
        .value()
    },
    usedCommentCategories () {
      return this._(this.comments).map('category').uniqBy('id').value()
    },
    usedTags () {
      return this._(this.comments).map('tags').flatten().uniqBy('tag.id').map('tag').value()
    },
    currentUser () {
      const users = this.comments.map(comment => comment.user)
      return this._.find(users, { id: this.claims.sub })
    }
  },
  watch: {
    selectedCategory: function () {
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.validate()
      })
    }
  },
  mounted () {
    console.log(this.$options.name, 'mounted')
    this.refetch()
    if (this.$refs.form) this.$refs.form.validate()
  },
  methods: {
    refetch () {
      console.log(this.$options.name, 'Re-fetching')
      this.$apollo.queries.comments.refetch()
    },
    async $imgAdd (pos, $file, refName) {
      try {
        const formData = new FormData()
        formData.append('event', this.currentEvent.id)
        formData.append('project', this.project.id)
        formData.append('image', $file)
        const response = await this.$api.post(
          '/upload-image',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        if (!response.data.ok) {
          throw new Error(response.data.output)
        } else {
          this.$refs[refName].$img2Url(pos, response.data.output.viewImage)
        }
      } catch (error) {
        this.$notify({
          group: 'dashboard',
          type: 'error',
          title: 'Mavon Error',
          text: error.message,
          duration: 10000
        })
      }
    },
    async createMutation (value) {
      const now = this.$moment().utc(0).unix()
      this.createLoading = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/gql/createProjectComment').default,
          variables: value,
          update: (store, { data: { createProjectComment } }) => {
            // Read from cache
            const cachedData = store.readQuery({
              query: require('@/gql/getProjectComments').default,
              variables: { project_id: this.project.id }
            })
            const data = this._.cloneDeep(cachedData)
            // Write it back
            store.writeQuery({
              query: require('@/gql/getProjectComments').default,
              variables: { project_id: this.project.id },
              data: {
                comments: this._(data.comments).unionBy([createProjectComment], 'id').orderBy(['created'], ['desc']).value()
              }
            })
          },
          optimisticResponse: this.currentUser
            ? {
                __typename: 'Mutation',
                createProjectComment: {
                  __typename: 'ProjectComment',
                  id: -1,
                  project: this.project,
                  category: this.selectedCategory,
                  user: this.currentUser,
                  value: value.value,
                  tags: this.selectedTags.map((tag, index) => ({
                    __typename: 'ProjectCommentTag',
                    id: -1 * ++index,
                    tag: {
                      __typename: 'EventTag',
                      ...tag
                    }
                  })),
                  created: now,
                  updated: now
                }
              }
            : undefined
        })
      } catch (error) {
        console.error(error)
      }
      this.createLoading = false
      this.commentValue = ''
      this.selectedTags = []
    }
  },
  apollo: {
    comments: {
      query: require('@/gql/getProjectComments').default,
      variables () {
        return {
          project_id: this.project.id
        }
      },
      subscribeToMore: [
        {
          document: require('@/gql/subscribeProjectCommentCreated').default,
          variables () {
            return {
              project_id: this.project.id
            }
          },
          updateQuery: function (previousResult, { subscriptionData: { data: { projectCommentCreated } } }) {
            console.log(`Received 'projectCommentCreated' event, will attempt adding project ${projectCommentCreated.id}`)
            if (!projectCommentCreated) {
              console.log('Received \'projectCommentCreated\' event does not have required information attached to it, skipping subscription update')
              return previousResult
            }
            return {
              comments: this._.unionBy(previousResult.comments, [projectCommentCreated], 'id')
            }
          },
          onError: function (error) {
            console.error('subscribeProjectCommentCreated error', error)
          }
        },
        {
          document: require('@/gql/subscribeProjectCommentUpdated').default,
          variables () {
            return {
              project_id: this.project.id
            }
          },
          updateQuery: function (previousResult, { subscriptionData: { data: { projectCommentUpdated } } }) {
            console.log(`Received 'projectCommentUpdated' event, will attempt updating project ${projectCommentUpdated.id}`)
            if (!projectCommentUpdated) {
              console.log('Received \'projectCommentUpdated\' event does not have required information attached to it, skipping subscription update')
              return previousResult
            }
            return {
              comments: this._.unionBy(previousResult.comments, [projectCommentUpdated], 'id')
            }
          },
          onError: function (error) {
            console.error('subscribeProjectCommentUpdated error', error)
          }
        },
        {
          document: require('@/gql/subscribeProjectCommentDeleted').default,
          variables () {
            return {
              project_id: this.project.id
            }
          },
          updateQuery: function (previousResult, { subscriptionData: { data: { projectCommentDeleted } } }) {
            console.log(`Received 'projectCommentDeleted' event, will attempt removing comment ${projectCommentDeleted.id}`)
            if (!projectCommentDeleted) {
              console.log('Received \'projectCommentDeleted\' event does not have required information attached to it, skipping subscription update')
              return previousResult
            }
            return {
              comments: previousResult.comments.filter(comment => comment.id !== projectCommentDeleted.id)
            }
          },
          onError: function (error) {
            console.error('subscribeProjectCommentDeleted error', error)
          }
        }
      ]
    }
  }
}
</script>
